import { PrimaryButton } from "components/Common/Buttons/Button";
import { setIsErrorDialogOpen } from "features/licenses/licenseAssignmentErrors";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";

import { Grid, Skeleton, Typography } from "@mui/material";
import {
	selectNumLicenseAssignmentErrors,
	selectIsLicenseAssignmentErrorDialogOpen,
	selectLicenseAssignmentErrors,
} from "features/licenses/licenseAssignmentErrors";
import { useAppNavigate } from "hooks/useAppNavigate";
import { NAVIGATION_NAMES } from "utilities/constants/pages";
import { fetchLicenseAssignmentErrors } from "actions/licenseAssignmentErrorsActions";
import { LicenseAssignmentErrorDialog } from "../LicenseAssignmentErrorDialog";

import WarningIcon from "@mui/icons-material/Warning";
import styles from "./LicenseManagementButtonSection.module.scss";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { LicenseIcon } from "assets/cost/license_icon";
import useRole from "utilities/roleUtils/roleCheck";

export const LicenseManagementButtonSection = () => {
	const { dispatch } = useAuth();
	const { navigateToPage } = useAppNavigate();

	const { isLoading, isFetching } = useAppSelector(selectLicenseAssignmentErrors);
	const numLicenseAssignmentErrors = useAppSelector(selectNumLicenseAssignmentErrors);
	useApiOnce(fetchLicenseAssignmentErrors, { isLoading, isFetching });
	const isOpen = useAppSelector(selectIsLicenseAssignmentErrorDialogOpen);

	const { isActionRole } = useRole();
	return (
		<>
			<Grid container item className={styles.buttonContainer}>
				<Button
					className={styles.warningButton}
					onClick={() => {
						dispatch(setIsErrorDialogOpen(true));
					}}
					variant="outlined"
					disabled={isOpen || isLoading}
					size="medium"
					disableRipple
				>
					<Grid container className={styles.leftButtonContet}>
						{<WarningIcon className={styles.warningIcon} />}
						<Typography
							variant="body1"
							display="inline"
						>{`License assignment errors: `}</Typography>
						{isLoading ? (
							<Skeleton variant="text" className={styles.skeleton} />
						) : (
							<Typography variant="body1" display="inline">
								{numLicenseAssignmentErrors}
							</Typography>
						)}
					</Grid>
					<Grid container className={styles.rightButtonContent}>
						<ArrowForwardIcon fontSize="small" />
					</Grid>
				</Button>

				<PrimaryButton
					action={() => {
						navigateToPage(NAVIGATION_NAMES.ASSIGN_LICENSES.path);
					}}
					variantStyle="contained"
					size="medium"
					text="Assign licenses"
					className={styles.assignButton}
					icon={<LicenseIcon />}
					isDisabled={!isActionRole}
					disabledDescription={"You do not have permission to assign licenses."}
				/>
			</Grid>
			{isOpen && <LicenseAssignmentErrorDialog />}
		</>
	);
};
