import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import { useAppSelector, useAuth } from "hooks/hooks";
import {
	selectActiveLicenseCompleteDetails,
	selectLicenseAdjustmentDrawerState,
	setLicenseAdjustmentDrawerState,
} from "features/licenses/licenses";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as GoodJob } from "assets/skybert/GoodJob.svg";
import { useEffect, useMemo } from "react";
import { BuyLicenses } from "./BuyLicenses";
import { RemovalQuantity, SubscriptionVariant } from "types";
import { LicenseActionOperationType, LicenseAdjustmentDrawerType } from "utilities/constants/enums";
import { RemoveLicenses } from "./RemoveLicenses";

import styles from "./LicenseAdjustmentDrawer.module.scss";
import { SecondaryButton } from "components/Common/Buttons/Button";
import { getIcon } from "utilities/microsoftIconsUtils";
import { selectLastScheduledActions, selectRecipientMailState } from "features/scheduledActions";
import dayjs from "dayjs";
import { FIVE_MINUTES } from "utilities/constants/constants";

interface LicenseAdjustmentDrawerProps {
	handleCreateLicenseAction: (
		variant: SubscriptionVariant[] | RemovalQuantity[],
		operationType: LicenseActionOperationType,
	) => Promise<void>;
}

const LicenseAdjustmentDrawer = ({ handleCreateLicenseAction }: LicenseAdjustmentDrawerProps) => {
	const { dispatch } = useAuth();
	const completeLicenseDetails = useAppSelector(selectActiveLicenseCompleteDetails);

	const handleCloseLicenseAdjustmentDrawer = () => {
		dispatch(
			setLicenseAdjustmentDrawerState({
				drawerType: "",
				isOpen: false,
				showConfirmationPage: false,
			}),
		);
	};

	const { isOpen, drawerType, showConfirmationPage } = useAppSelector(
		selectLicenseAdjustmentDrawerState,
	);

	useEffect(() => {
		// Hide Userback widget when drawer is open
		try {
			isOpen ? window?.Userback?.hide() : window?.Userback?.show();
		} catch (error) {}
	}, [isOpen]);

	const { mail: recipientMail } = useAppSelector(selectRecipientMailState);

	const lastScheduledActions = useAppSelector(selectLastScheduledActions);

	const content = useMemo(() => {
		const handlePrimaryAction = async (
			variants: RemovalQuantity[] | SubscriptionVariant[],
			operationType: LicenseActionOperationType,
		) => {
			await handleCreateLicenseAction(variants, operationType);
			dispatch(
				setLicenseAdjustmentDrawerState({
					showConfirmationPage: true,
					error: false,
				}),
			);
		};

		if (showConfirmationPage) {
			let headerText = "";
			let descriptionText = <></>;

			if (drawerType === LicenseAdjustmentDrawerType.BUY_LICENSES) {
				headerText = "Purchase order confirmed";
				descriptionText = (
					<Typography variant="description" textAlign={"center"}>
						It can take up to <b>{FIVE_MINUTES}</b> minutes before the licenses are
						available for assignment
					</Typography>
				);
			} else if (drawerType === LicenseAdjustmentDrawerType.REMOVE_LICENSES) {
				headerText = "Removal order confirmed";
				descriptionText = (
					<>
						<Typography variant="description" textAlign={"center"}>
							Licenses will be removed on
						</Typography>
						{lastScheduledActions.map((scheduledAction) => (
							<Typography
								mt={1}
								variant="description"
								display="block"
								key={
									scheduledAction?.ActionId /* Assuming there's an ID or some unique identifier */
								}
							>
								{Math.abs(scheduledAction?.QuantityChange ?? 0)} x{" "}
								{dayjs(scheduledAction?.ExecutionDate).format("DD.MM.YYYY")}
							</Typography>
						))}
					</>
				);
			}

			return (
				<Grid className={styles.confirmationPage}>
					<Grid container item xs={12}>
						<Grid item xs={12}>
							<Typography variant="h1" fontWeight={500} textAlign="center" mt={3}>
								{headerText}
							</Typography>
							{recipientMail !== "" && (
								<Typography variant="body1" textAlign="center" mt={1}>
									Confirmation will be is sent to {recipientMail}
								</Typography>
							)}
						</Grid>
						<svg className={styles.skybert} viewBox="0 10 40 40">
							<GoodJob />
						</svg>
					</Grid>
					<Grid container item xs={12} className={styles.confirmationPageText}>
						<InfoIcon className={styles.infoIcon} />
						<Typography variant="description" textAlign={"center"}>
							{descriptionText}
						</Typography>
					</Grid>
					<Grid container item xs={12} justifyContent={"center"} mt={10}>
						<SecondaryButton
							text="Close"
							action={handleCloseLicenseAdjustmentDrawer}
							variantStyle="outlined"
							size="large"
						/>
					</Grid>
				</Grid>
			);
		}

		switch (drawerType) {
			case LicenseAdjustmentDrawerType.BUY_LICENSES:
				return <BuyLicenses handlePrimaryAction={handlePrimaryAction} />;
			case LicenseAdjustmentDrawerType.REMOVE_LICENSES:
				return (
					<RemoveLicenses
						licenseSubscriptionVariantGroup={completeLicenseDetails ?? {}}
						handlePrimaryAction={handlePrimaryAction}
					/>
				);
			default:
				return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		drawerType,
		completeLicenseDetails?.consumedQuantity,
		completeLicenseDetails?.variants,
		completeLicenseDetails?.totalQuantity,
		completeLicenseDetails?.friendlyName,
		showConfirmationPage,
	]);

	return (
		<>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={handleCloseLicenseAdjustmentDrawer}
				PaperProps={{ className: styles.drawer }}
				className={styles.licenseAdjustmentDrawerWrapper}
				variant="temporary"
				aria-labelledby="license-adjustment-drawer"
				hideBackdrop={false}
			>
				<Grid item className={styles.closeButtonContainer}>
					<IconButton onClick={handleCloseLicenseAdjustmentDrawer}>
						<CloseIcon />
					</IconButton>
				</Grid>
				<Grid container className={styles.header}>
					<Grid item>
						{showConfirmationPage ? (
							<></>
						) : (
							<Grid container className={styles.titleContainer}>
								<svg width="20" height="20" viewBox="0 0 60 60">
									{getIcon({
										iconName: completeLicenseDetails?.friendlyName as string,
									})}
								</svg>
								<Typography variant="h2">
									{completeLicenseDetails?.friendlyName || ""}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
				{content}
			</Drawer>
		</>
	);
};

export { LicenseAdjustmentDrawer };
